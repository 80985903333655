<template>
  <div class="bg bg17">
    <div class="class--con">
      <class-but @forward="forward"/>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";


export default {
  name: "photoContent",
  components: {ClassBut},
  data() {
    return {}
  },
  methods: {
    forward() {
      this.$router.push({
        name: 'contentImagination',
        query: this.$route.query,
      })
    },
  }
}
</script>
<style lang="less" scoped>
@import "reportClass.less";
</style>
